import MainContent from "../../components/what-are-we-doing/What-are-we-doing";
import Main from "../../components/Main/Main";
import Slider from "../../components/slider copy/slider";


const Home = () => {
  return (
    <>
    
      <Slider />
      <MainContent/>
      <Main/>
     
     
    </>
  );
};

export default Home;
