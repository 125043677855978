import "./main.scss"
import { Col, Container, Row, Figure } from 'react-bootstrap';
import news from "../../pages/News/news.json"
import { Link } from "react-router-dom";

const Main = () => {
  return (<>
    <h2 className="main-title"
      data-aos-anchor-placement="bottom-bottom" >Haberler & Duyurular </h2>
    <div className="mains">
      <Container className="container-cards">
        <Row className="g-4">
         
            {
              news.map((n,index) =>
              <Col sm={12} md={4}>
                <Link to="/bizden-haberler" key={index}>
                  <Figure className="news" >
                    <Figure.Image
                      width={350}
                      height={350}
                      alt="haberler"
                      src={(require(`../../assets/img/${n.image}`))}
                    />
                    <Figure.Caption className="news-content">
                    Bizden haberler yakında...
                    </Figure.Caption>
                  </Figure>
                </Link>
                </Col>
              )
            }

     

        </Row>


      </Container>


    </div>

  </>


  )

}

export default Main