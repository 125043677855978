import React from 'react'
import { Container, Col, Row, Figure } from "react-bootstrap"
import { Link } from 'react-router-dom';
import content from "./what-are-we-doing.json"
const WhatAreWeDoing = () => {
    return (
        <Container  className='items'>

            <h1 className='main-title' >Neler Yapıyoruz ?</h1>
            <Row>
               
                    {
                        content.map((c)=>( 
                            <Col xs={12} md={4} key={c.id}>
                        <Link to={c.to} >
                        <Figure >
                            <Figure.Image
                                width={500}
                                height={500}
                                alt={c.alt}
                                src={require(`../../assets/img/${c.image}`)}
                            />
                            <Figure.Caption style={{fontSize:"17px",textAlign:"center"}}>
                                {c.title}
                            </Figure.Caption>
                        </Figure>
                    </Link>
                    </Col>))
                    }
            </Row>

        </Container>
    )
}

export default WhatAreWeDoing