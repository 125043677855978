import React, { useEffect, useState } from "react";
import "./NavigationBar.scss";
import logo from "../../assets/img/meta_logo_2.png";
import { Navbar, Container, NavDropdown, Nav, NavbarBrand, Button, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import {AiOutlineMenu} from "react-icons/ai"
const NavigationBar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
const handleMenuItemClick=()=>{
  setShow(false)
}
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClass = ` ${scrolled ? "scrolled" : ""}`;

  return (

    <Navbar sticky="top" expand="lg" className={navbarClass} style={{padding:"35px"}}>
      <Container fluid>
        <div>
          <Link to="/">
          <img
            src={logo}
            className="d-inline-block align-center"
            alt="metadrama-logo"
          />
          </Link>
        </div>
        <Nav className="m-auto ">
          <Nav.Link as={Link} to="/">ANA SAYFA</Nav.Link>
          <NavDropdown title="HAKKIMIZDA" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/hakkimizda">Biz Kimiz ?</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/takim">Kadromuz</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/neden-meta">Neden Meta ?</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="NELER YAPIYORUZ ?" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/danismanlik">Danışmanlık ve Eğitimler</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/kurumsal-yasam-egitimleri">Kurumsal Yaşam Eğitimleri</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/yaratici-drama">Yaratıcı Drama Atölyeleri</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/projelerimiz">PROJELERİMİZ</Nav.Link>
          <Nav.Link as={Link} to="isbirlikcilerimiz">İŞ BİRLİKLERİMİZ</Nav.Link>
          <Nav.Link as={Link} to="/bizden-haberler">BİZDEN HABERLER</Nav.Link>
        </Nav>
        <Button id="btnOffanvas" 
        onClick={handleShow}
        style={{background:"transparent",border:"none"}}
        >
         <AiOutlineMenu/>
        </Button>
        <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Nav className="m-auto ">
          <Nav.Link as={Link} to="/" onClick={handleMenuItemClick}>ANA SAYFA</Nav.Link>
          <NavDropdown title="HAKKIMIZDA" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/hakkimizda" onClick={handleMenuItemClick}>Biz Kimiz ?</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/takim" onClick={handleMenuItemClick}>Kadromuz</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/neden-meta" onClick={handleMenuItemClick}>Neden Meta ?</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="NELER YAPIYORUZ ?" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/danismanlik" onClick={handleMenuItemClick}>Danışmanlık ve Eğitimler</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/yaratici-drama" onClick={handleMenuItemClick}>Yaratıcı Drama</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/kurumsal-yasam-egitimleri" onClick={handleMenuItemClick}>Kurumsal Yaşam Eğitimleri</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/projelerimiz" onClick={handleMenuItemClick}>PROJELERİMİZ</Nav.Link>
          <Nav.Link as={Link} to="/isbirlikcilerimiz" onClick={handleMenuItemClick}>İŞ BİRLİKLERİMİZ</Nav.Link>
          <Nav.Link as={Link} to="/bizden-haberler"onClick={handleMenuItemClick}>BİZDEN HABERLER</Nav.Link>
        </Nav>
        </Offcanvas.Body>
      </Offcanvas>
      </Container>
    </Navbar>
   

  );
};

export default NavigationBar;
