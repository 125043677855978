import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavigationBar from './components/NavigationBar/NavigationBar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import Team from './pages/Team/Team';
import WhyMeta from './pages/WhyMeta/WhyMeta';
import YaraticiDrama from './pages/ImagesUrl/YaraticiDrama';
import Danismanlik from './pages/ImagesUrl/Danismanlik';
import News from './pages/News/News';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import WhatAreWeDoing from './components/what-are-we-doing/What-are-we-doing';
import KurumsalYasamEgitimleri from './pages/ImagesUrl/kurumsal-yasam-egitimleri';
import Project from './pages/Project/project';
import Collaborator from './pages/Collaborator/collaborator';

function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <ScrollToTop />
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hakkimizda" element={<AboutUs />} />
          <Route path="/takim" element={<Team />} />
          <Route path="/NelerYapiyoruz" element={<WhatAreWeDoing />} />
          <Route path="/neden-meta" element={<WhyMeta />} />
          <Route path="/bizden-haberler" element={<News />} />
          <Route path="yaratici-drama" element={<YaraticiDrama />} />
          <Route path="/danismanlik" element={<Danismanlik />} />
          <Route path="/kurumsal-yasam-egitimleri" element={<KurumsalYasamEgitimleri />} />
          <Route path="/projelerimiz" element={<Project />} />
          <Route path="/isbirlikcilerimiz" element={<Collaborator />} />

        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
