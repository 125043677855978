import React from 'react'
import PageHeader from '../../components/page-header/page-header'
import { Container } from 'react-bootstrap'
import Spacer from '../../components/spacer/spacer'

const News = () => {
  return (
    <>

    <PageHeader title="Bizden Haberler"/>
    <Spacer height={50}/>
    <Container>
      <p>Bizden haberler çok yakında yayında ...</p>
    </Container>
    </>
  )
}

export default News