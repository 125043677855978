import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './footer.scss'
import { BsFillTelephoneFill, BsEnvelopeFill, BsFacebook, BsTwitter, BsLinkedin, BsInstagram } from "react-icons/bs";
import Copyrigt from './Copyright/Copyrigt';

const Footer = () => {
  return (
    <>
  
      <Container fluid className='footer-container'>
        <Row>
          <Col>
            <ul className='list adress-list'>
              <li>HAKKIMIZDA</li>
              <hr />
              <p>Tecrübeli teknik kadromuz, güvenilir ve verimli ekip olma anlayışıyla, müşteri memnuniyeti ve kalite ile birlikte, ülke ve dünya standartlarına uygun olarak hizmet sunmaktayız.</p>
            </ul>
          </Col>
          <Col>
            <ul className='list adress-list'>
              <li>İLETİŞİM</li>
              <hr />
              <li>Telefon : 
                <a href="tel:+9005056121383"> <BsFillTelephoneFill />+90 0505 612 13 83 </a></li>
              <li>Email :  <a href="mailto:info@metadrama.com"><BsEnvelopeFill /> info@metadrama.com</a></li>
              <li>Adres: Folkart Towers-İş Merkezi </li>
              <li>Adalet Mahallesi Manas Bulvarı No : 39 İç Kapı No: 34078 Bayraklı İZMİR</li>
            </ul>
          </Col>
          <Col>
            <ul className='list sossial-list'>
              <li>SOSYAL MEDYA</li>
              <hr />
              <li><a href="/"><BsFacebook style={{color:'blue',fontSize:"1.6rem"}}/> Facebook</a>
               </li>
              <li><a href="#/"><BsInstagram style={{color:'orangered',fontSize:"1.6rem"}}/> Instagram</a></li>
              <li><a href="/"><BsLinkedin style={{color:'blue',fontSize:"1.6rem"}}/> Linkedln</a></li>
              <li><a href="/"><BsTwitter style={{color:'aqua',fontSize:"1.6rem"}}/> Twitter</a></li>
            </ul>
          </Col>
        </Row>

        <Copyrigt />
      </Container>
    </>
  );
};

export default Footer;
