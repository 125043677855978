import { Container, Accordion, Card, Row, Col } from 'react-bootstrap'
import PageHeader from '../../components/page-header/page-header'
import Spacer from '../../components/spacer/spacer'

const KurumsalYasamEgitimleri = () => {

  const kurumsalYasamEgitimleri = [
    {
      id: 1,
      title: "Yalın Dönüşüme Destek Atölyeleri",
      image: "yalin_donusum_destek.png",
      content: ["Kaizen ;", "Kaizen yapan çalışanlar, bireysel ve duygusal olarak da gelişir.", "5S ; ", "İş yerinde ki karmaşayı çok basit adımlarla düzene dönüştürür.", "Problem Çözme Teknikleri ;", "armaşık bir resmi yavaşça ve zarifçe çözerken ki sanatsal bir yolculuktur.", "Sürdürülebilirlik (Karbon Ayak İzi, Çevreye Duyarlılık) ;", "Sürdürülebilirlik, doğanın dengesini gözeterek geleceği karşılamaktır."]

    },
    {
      id: 2,
      title: "Kalite Yönetimi Atölyeleri",
      image: "kalite_yonetim_sistemleri.jpg",
      content: ["Süreç Yönetim ;", " Hedeflere ulaşmanın yolu, süreçlerin doğru tasarlanmasından geçer.", "Risk Odaklı Düşünme ;", "Risk odaklı düşünme, geleceğinizi tahmin etmek yerine onu inşa etmeye odaklanmanın bir yoludur.", "Politika ve Strateji Geliştirme ;", "Politika ve strateji geliştirme, bir organizasyonun yol haritasını tasarlayarak, hedeflerine yaklaşmasıdır."]

    },
    {
      id: 3,
      title: "Çalışanlara Güç Katma Atölyeler",
      image: "calisanlara_guc_katma.jpg",
      content: ["Motivasyon ;", "Daha iyi iş sonuçlar için ihtiyacınız olan ilk şeydir.", "İletişim Becerileri ;", "İş sonuçlarını iyileştirmeye birbirimiz anlamaktan başlayalı.", "Çatışma Becerileri (itirazları Yönetme) ; ", "Çok kolay yöntemler uygulayarak, çatışmaları faydaya dönüştürebiliriz. "]

    },
    {
      id: 4,
      title: "İş Yerine Değer Katma Atölyeleri",
      image: "kalite_yonetim_sistemleri.jpg",
      content: ["Performans Görüşme Teknikleri ;", "İyi bir geri bildirim, başarı potansiyelini ortaya çıkarır.", "İşe Alım Görüşme Teknikleri ;", "Doğru insanı seçmenin yolu, etkili dinleme ve gözlemden geçer.", "Doğru Ekibi Oluşturma ;", " ihtiyacı doğru belirleme ve iyi planlama işidir.", "Performans Yönetimi ;", "Veriler, başarının anahtarını sunar.", "Stres ve Tükenmişlikle Başa Çıkma ;", "Çalışanlar mutlu ve sağlıklı olduğunda, iş yerinin atmosferi iyileşir ve performansı artar.", "İş Yaşam Dengesi ;", "Çalışanlar mutlu ve sağlıklı olduğunda, iş yerinin atmosferi iyileşir ve performansı artar.", "Butik Drama Atölyesi ;", "İhtiyaca Özel Yaratıcı Drama Atölyeleri."]

    },
    {
      id: 5,
      title: "Biraz da Eğlenelim Atölyeleri",
      image: "birazda_eglenelim.png",
      content: ["Sanat Atölyeleri(Edebiyat ve plastik sanatlar) ;", "Yaratıcılığınızın serbest akışını keşfetmek ve sanatın büyüleyici dünyasına adım atmak için mükemmel bir fırsattır.", "Oryantiring ;", "Gizli hazineyi bulma ve keşfetme tutkusunu tetikleyen heyecan verici bir maceradır.", "Müze Atölyeleri ;", "Sanat ve tarih ile etkileşimin büyülü dünyasına kapı aralar.", "Önemli Günler Atölyeleri ;", "Her zaman elinizden gelenin daha iyisini yapın."]

    },
    {
      id: 6,
      title: "Topluma Bakış Atölyeleri",
      image: "topluma_bakis_atolyeleri.jpg",
      content: ["Çocuk Hakları ;", "Çocuklar bir gökkuşağı gibidir ve renkleri ile güzeldir, onları açığa çıkartmak gerekir.", "Kadın Hakları ;", "Her kadın, kendi içinde bir evreni barındırır ve bu evrende sonsuz güzellikleri ve potansiyeli ortaya çıkarır.", "Toplumsal Cinsiyet Eşitliği ;", "Cinsiyet rollerini sorgularken, her bir hikayenin toplumsal dokuya nasıl renk kattığını keşfetmenizi sağlar.", "Flört Şiddeti ;", "İlişkilerdeki güç dinamiklerini anlama ve sağlıklı, sevgi dolu bağlantılar kurma yolunda ışık tutan bir deneyim sunar."]

    },
  ]



  return (
    <>

      <PageHeader title="Kurumsal Yaşam Eğitimlerimiz" />
      <Container className='mt-5'>
        <Accordion defaultActiveKey="0">
          {
            kurumsalYasamEgitimleri.map((item, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>
                  <Card style={{ border: "none", paddingTop: "20px" }}>
                    <Row className="g-0">
                      <Col xs={12} md={5} lg={3}>
                      <Spacer height={20}/>
                        <div>
                          <Card.Img
                            src={require(`../../assets/img/${item.image}`)}
                            className="img-fluid"
                            alt=""
                            style={{ borderRadius: "0" }}
                          />
                        </div>

                      </Col>
                      <Col xs={12} md={7} lg={9}>
                        <Card.Body className="pt-3 text-center">
                          <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                            <span>
                              <p> <strong>{item.content[0]}</strong> {item.content[1]}</p>
                              <p><strong>{item.content[2]}</strong>  {item.content[3]}</p>
                              <p><strong>{item.content[4]}</strong>  {item.content[5]}</p>
                              <p><strong>{item.content[6]}</strong>   {item.content[7]}</p>
                              <p><strong>{item.content[8]}</strong>   {item.content[9]}</p>
                              <p><strong>{item.content[10]}</strong>   {item.content[11]}</p>
                              <p><strong>{item.content[12]}</strong>   {item.content[13]}</p>
                            </span>

                          </Card.Text>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>

                </Accordion.Body>
              </Accordion.Item>

            ))
          }
        </Accordion>
      </Container>
    </>
  )
}

export default KurumsalYasamEgitimleri