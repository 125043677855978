import "./page-header.scss"

const PageHeader = ({title}) => {
  return (
    <div>
        <div className="page-header">
        <h1>{title}</h1>
        </div>

    </div>
  )
}

export default PageHeader