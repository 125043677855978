import React from 'react'
import PageHeader from '../../components/page-header/page-header'
import { Card, Col, Container, Row } from 'react-bootstrap'
import collab from "./collabrator.json"
import Spacer from '../../components/spacer/spacer'
const
  Collaborator = () => {
    return (
      <>
        <PageHeader title='İşbirlikçilerimiz' />
        <Container>
          <Row>
            <Col>
              {collab.map((c, index) =>
              <Card style={{ border: "none", paddingTop: "20px" }}key={index}>
                <Row className="g-0">
                  <Col xs={12} md={5} lg={3} >
                    <Spacer height={20} />
                    <div>
                      <Card.Img
                        src={require(`../../assets/img/${c.image}`)}
                        className="img-fluid"
                        alt=""
                        style={{ borderRadius: "0" }}

                      />
                    </div>

                  </Col>
                  <Col xs={12} md={7} lg={9}>
                    <Card.Body className="pt-3 text-center">
                      <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                        <span><p className=' mt-4'>{c.desc}</p></span>
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>)}

            </Col>
          </Row>
        </Container>
      </>
    )
  }

export default Collaborator