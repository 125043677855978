import { Container, Accordion, Card, Row, Col } from 'react-bootstrap'
import PageHeader from '../../components/page-header/page-header'
import "./danisman.scss"
import Spacer from '../../components/spacer/spacer'


const Danismanlik = () => {

  const egitimDanismanlik = [
    {
      title: "Kalite Yönetim Sistemleri",
      image: "kalite_yonetim_sistemleri.jpg",
      content: ["Deneyimli ekibimiz ile iş süreçlerinizi optimize etmek, verimliliği artırmak ve en önemlisi, müşteri memnuniyetini sağlamak için kalite yönetim sistemlerinizi başarıyla uygulamanıza rehberlik ediyoruz.", "ISO 9001 ve diğer uluslararası standartlarla uyumlu çözümlerimiz, organizasyonunuzun kalite ve sürekli iyileştirmeye odaklanmasını sağlarken, aynı zamanda rekabet avantajı elde etmenize yardımcı oluyoruz."]

    },
    {
      title: "Kaizen",
      image: "kaizen.png",
      content: ["Makina Mühendisleri Odasının İzmir Şubesinde, Endüstri Mühendisliği Meslek Dalı Komisyonu kapsamında bu yıl 9.su düzenlenen “Kaizen Paylaşımları Etkiniliği”nin model oluşumunda, değerlendiricilerin yetiştirilmesinde ve organizasyonunda ilk gününden bu yana aktif görev almaktayız.", "Bu deneyimimizi işletmelerin her gün küçük sıçramalarla büyük kazanımlar elde etmesi, insan kaynağınızın zihinsel, duygusal ve sosyal açılardan güçlenip hedeflerinize uyumlanmasına destek olan eğitim ve danışmanlık hizmetleri sunuyoruz."]
    },
    {
      title: "Süreç Yönetimi",
      image: "sürec_yönetimi.jpg",
      content: ["İşletmelerde gereksinim duyulan, hayata geçirmek için çaba harcanan her türlü yönetim sistemi faaliyeti ve iyileştirme çalışmaları, iyi / doğru tasarlanıp standardize edilmiş süreçlere uygulanmayı gerektirir.", "Bu nedenle süreç yönetimi, bir işletmeyi hayatta / ayakta tutan en önemli zemini oluşturur. Bu konudaki eğitim ve danışmanlık faaliyetlerimiz ile kuruluşunuzun hedeflerine, hızlı ve etkili şekilde hizmet etmenize,  kolay uygulanabilen süreç yönetimi çözümlerini bulmanıza destek oluyoruz."]
    },
    {
      title: "Dökümantasyon",
      image: "dokumantasyon.png",
      content: ["Yönetim Sistem Standartlarının yüksek seviyeli yapıya dönüştürülmesi ile birlikte dokümantasyon ve kayıtlar kuruluşun yapısına özgü olarak esnek hale getirilebiliyor.", "Verdiğimiz eğitim ve danışmanlıklarda dokümantasyonun sade ve etkin kullanılmasına destek oluyoruz. Ayrıca hızlı değerlendirilen, doğru kararlar aldıran verilerinizi/kayıtlarınızı oluşturmanıza rehberlik ediyoruz."]
    },
    {
      title: "Kurumsal Risk Yönetimi",
      image: "kurumsal_risk_analizi.jpg",
      content: ["Risk Temelli Düşünme, olası tehlike ve belirsizliklerle mücadele etmek kadar olası fırsatları yakalamak üzere hazırlıklı olmayı gerektirir.", "Kurumsal kimliğin oluşmasında risk temelli yaklaşım, kurumlara farkındalık yaratmaktadır.", "Bu konudaki eğitim ve danışmanlıklarımız, süreçlerin risklerine ve fırsatlarına daha yakından bakıp hızlı çözümler ve etkin müdahaleler yapılmasına destek olmaktadır"]
    },
    {
      title: "İnsan Kaynakları Yönetimi",
      image: "insan_kaynaklari_yönetimi.jpg",
      content: ["İnsan Kaynakları yönetiminin tüm başlıklarında, uygulamaların başarılı ve adil olması kadar bu şekilde algılanması da önemlidir.", "Hizmetlerimizde bu alanda uygulanmaya başlayan ve başlayacak tüm faaliyetlerin sağlam temellere oturtulmasına, kolay uygulanmasına, olumlu algılanmasına yön gösteriyoruz"]
    },
    {
      title: "Performans Yönetimi",
      image: "performans_yönetimi.jpg",
      content: [
        "Performans Yönetimi, çalışanlarınızın potansiyelini en üst düzeye çıkarırken, kurumunuzun büyüme ve başarı yolculuğunda bir katalizör rolü oynar.", "Kurumun ana hedefleri ile uyumlu ve etkili bir performans yönetimi sistemi; ekiplerin aynı yöne bakması ve kolayca raporlanabilmesi ile mümkün hale gelir."]
    },
    {
      title: "Problem Çözme,Analiz ve Raporlama",
      image: "problem_cözme_ve_analiz.jpg",
      content: ["Veri tabanlı kararlar almak, hedeflerinize daha hızlı ve kesin bir şekilde ulaşmanızı sağlar. Verilerin doğru kaynaklardan doğru miktarda toplanması ve analizi için uygun tekniklerin seçimi, problemleri doğru analiz etmenin püf noktasıdır.", "Doğru verileri doğru şekilde yorumladığınızda, işletmeniz için önemli avantajlar elde edebilirsiniz. Biz, problem çözme sürecinizi kolaylaştırmak ve sonuçlarınızı optimize etmek için buradayız.", "Sunduğumuz hizmetler ile kurumunuz için özelleştirilmiş çözümler sunarak, en iyi çözümleri elde etmenize destek oluyoruz."]
    },
    {
      title: "Koçluk",
      image: "kocluk.jpg",
      content: ["İletişimde,  koçluk tekniklerinde kullanılan soru dizinleri, kişilerin farkında olarak ya da olmayarak sahip oldukları bilgi potansiyellerini kullanılabilir hale getirir. Böylece, kişiler en yüksek yaratıcılık potansiyellerini, kendileri için en yüksek faydayı elde ederler.  Koçluk tekniklerini uygulama becerimizi geliştirdiğimizde, karşımızdaki kişilerle yaratıcı ve güçlü çözümler üretmeye hazır, diyaloğun soruna değil çözüme odaklandığı, çözümün tıkandığı noktalarda yeni yaratıcılık alanlarına hızla geçebilen yapıda iletişim kurarız.", "Bu becerileri iş yaşamınızda kullanmanıza yol göstererek, kişisel ve takım olarak iş sonuçlarınızın iyileşmesine ve sürdürülebilir olmasına destek oluyoruz."]
    }


  ]

  return (
    <>

      <PageHeader title={"Danışmanlık ve Eğitimler"} />
      <Container className='mt-5'>
      <Accordion defaultActiveKey="0">
    {
      egitimDanismanlik.map((item, index) => (
        <Accordion.Item eventKey={index.toString()} key={index}>
          <Accordion.Header>{item.title}</Accordion.Header>
          <Accordion.Body>
          <Card style={{border:"none" , paddingTop:"20px"}}>
              <Row className="g-0">
                <Col xs={12} md={5} lg={3}>
                  <Spacer height={20}/>
                  <div>
                  <Card.Img
                    src={require(`../../assets/img/${item.image}`)}
                    className="img-fluid"
                    alt=""
                    style={{borderRadius:"0"}}

                  />
                  </div>
                  
                </Col>
                <Col xs={12} md={7} lg={9}>
                  <Card.Body className="pt-3 text-center">
                    <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                      <span>
                      <p>{item.content[0]}</p>
                      <p>{item.content[1]}</p>
                      <p>{item.content[2]}</p>
                     
                      
                      </span>
                    
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
           
          </Accordion.Body>
        </Accordion.Item>
      ))
    }
  </Accordion>
      </Container>
    </>

  )
}

export default Danismanlik