import React from 'react'
import PageHeader from '../../components/page-header/page-header'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Spacer from '../../components/spacer/spacer'
import "./team.scss"
import persons from "./team.json"
const Team = () => {
  return (
    <>

      <PageHeader title="Kadromuz" />
      <Spacer height={20} />
      <Container className='team'>
        {
          persons.map((person) => (
            <Card key={person.id}>
              <Row className="g-0">
                <Col xs={12} md={3}>
                  <div>
                    <Card.Img
                      src={require(`../../assets/img/${person.image}`)}
                      className="img-fluid"
                      alt="metadrama team foto"

                    />
                  </div>

                </Col>
                <Col xs={12} md={9}>
                  <Card.Body className="pt-3 text-center">
                    <Card.Title className='text-start fs-6'>
                      <div>
                      <ul className='person-info-list'>
                        <li>{person.name}</li>
                        <li>{person.job}</li>
                        <li>{person.duty}</li>
                        <li>{person.email}</li>
                      </ul>

                      </div>
                  
                    </Card.Title>
                
                      <div>
                      <ul  style={{ fontSize: "1rem",padding:0,listStyleType:"none"}} className='text-start'>
                        <li>{person.info[0]}</li>
                        <li>{person.info[1]}</li>
                        <li>{person.info[2]}</li>
                        <li>{person.info[3]}</li>
                        <li>{person.info[4]}</li>
                        <li>{person.info[5]}</li>
                        <li>{person.info[6]}</li>
                        <li>{person.info[7]}</li>
                        <li>{person.info[8]}</li>
                        <li>{person.info[9]}</li>

                      </ul>
                      </div>

                  </Card.Body>
                </Col>
              </Row>
            </Card>
          ))
        }

        {/* <Row>
          <Col md={3}>
            
              <img src={team1} alt="metadrama team foto" />
          
          </Col>
          <Col md={9}>
            <div className='team-info mb-2'>
            <span>S. Elif ÖZARI ERBEK</span>
            <span>Endüstri Yüksek Mühendisi</span>
            <span>Kurucu, Eğitmen</span>
            <span>elif.ozari@metadrama.com.tr</span>
            </div>
           
      
            <p>1970 İzmir doğumlu. Dokuz Eylül Üniversitesi Mühendislik Fakültesi Endüstri Mühendisliği Bölümü mezunu.
              1991 yılından itibaren, çeşitli özel sektör kuruluşlarında Kalite Müdürlüğü, Yönetim Temsilciliği, İnsan Kaynakları Müdürlüğü görevleri ile Kalite Yönetim Sistemlerinin kurulması ve geliştirilmesi projeleri yürüttü.
              1998-1999 D.E.Ü. İşletme Fakültesi “Proffessional Carreer Development” programını tamamladı.
              2000 yılından itibaren Kalder bünyesinde Ege Bölgesi Kalite Ödülü ve Yılın Başarılı Ekibi Ödülü süreçlerinde Baş değerlendiricilik yapıyor.
              2004 yılından itibaren çeşitli KOBİ’lerde Kalite Yönetim Danışmanlığı yapıyor.
              2005 yılında Kanada merkezli Erickson College’in “The Art and The Science Of Coaching” programını tamamladı. 2006 yılından itibaren  “Professional Coach” lisansı ile “Kalite Yönetim Koçluğu” yapıyor.
              2006 yılından bu yana Çağdaş Drama Derneği bünyesinde Yaratıcı Drama Liderliği yanı sıra yönetim kurulunda aktif görev almaktadır.
              2011 yılından bu yana ISO 9001 Kalite Yönetim Sistemi Denetimleri yapıyor.
              2012 yılından bu yana TMMOB bünyesinde ve işletmelerde Kalite Yönetimi, Kaizen, Süreç/Risk/Performans Yönetimi gibi iş süreçlerinin birçok alanında danışmanlık ve eğitimler veriyor.
            </p>
          </Col>
        </Row> */}
      </Container>
    </>
  )
}

export default Team