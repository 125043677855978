import { Swiper, SwiperSlide } from "swiper/react"
import slides from "./sliders.json"
import "./slider.scss"
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/effect-fade"

const Slider = () => {
    return (
        <Swiper className="slider"
            effect="fade"
            pagination={{ clickable: true }}
            navigation={true}
            modules={[Navigation, Pagination, EffectFade, Autoplay]}
            autoplay={{
                delay: 6000,
                disableOnInteraction: false,
            }}>

                {
                    slides.map(slide =>
                        <SwiperSlide key={slide.id}>

                            <div className="content">
                            </div>
                            <img src={require(`../../assets/img/${slide.image}`)}
                             
                            />

                        </SwiperSlide>)
                }

           
        </Swiper >
    )
}

export default Slider