import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./project.scss"
import PageHeader from '../../components/page-header/page-header'
import egitimde_drama from "../../assets/img/egitimde_drama.png"
const Project = () => {
    return (
        <>
            <PageHeader title="Projelerimiz" />
            <Container>
                <Row>
                    <Col md={3}>
                        <img src={egitimde_drama} alt="" className='project-img'/>
                    </Col>
                    <Col md={9} className='mt-5'>
                        <h5>Eğitimde Drama – Sanata Evet (ED-SE)</h5>
                        <p>Köy enstitüleri eğitim modelinin yaratıcı drama yöntemi ile günümüze uyarlanarak hayata geçirilmesidir. Bu proje ile eğitim kurumlarının, mevcut eğitim programlarındaki bilgileri, öğrencilerine daha etkin aktarması öngörülmektedir. </p>
                        <p>Bu projenin amacı, öğretmenlerin, öğrencilerin ve eğitim kurumlarının ortaya koyduğu fark ile elde ettikleri başarılı sonuçların, Kaizen metodolijisi ile ölçülüp ve değerlendirildiği bir projeye dönüştürülmesi ve “Paylaşım Günleri” aracılığıyla yerel ve ulusal ölçekte duyurulmasıdır.</p>
                    </Col>
                    <Col md={3}>
                      
                    </Col>
                    <Col md={9}>
                        <h5>Eğitimde Drama – Çevreye Duyarlılık Hareketi (TEMA Vakfı)</h5>
                        <p>Toplumda Çevreye ve doğaya karşı duyarlılığın yaratıcı drama yöntemiyle farkındalık yaratılmasını amaçlayan projedir. Bu proje ile öğretmenlerin derslerinde öğrencilere, kendilerinin de yaratıcılıklarını kullandıkları ezber bozan çevre duyarlılığı geliştirmeleri, üretimine katkıda bulundukları görünür projelerle motivasyon ve çevre kalitesi açısından güçlenmeleri amaçlanmaktadır.</p>
                    </Col>
                </Row>
            </Container>
        </>
    )

}

export default Project