import React from "react";
import { Container } from "react-bootstrap";
import nedenmeta from "../../assets/img/nedenmeta.png";
import PageHeader from "../../components/page-header/page-header";
import Spacer from "../../components/spacer/spacer";

const WhyMeta = () => {
  return (
    <>
    <PageHeader title="Neden Meta"/>  
    <Spacer height={20}/>
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "100%",
       
      }}
    >
      <img
        src={nedenmeta}
        alt=""
        style={{
          maxWidth: "100%", // Resmi genişliği yüzdeye göre ayarlar
          height: "auto",    // Yüksekliği otomatik ayarlar
        }}
      />
    </Container>
    </>
  );
};

export default WhyMeta;
