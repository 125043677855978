import { Container, Accordion, Card, Row, Col, Nav, Tabs, Tab } from 'react-bootstrap'
import PageHeader from '../../components/page-header/page-header';
import cocuk_atolye_img from "../../assets/img/cocuk_atolyeleri.jpg"
import kultur_atolye from "../../assets/img/kultur_felsefe_mitoloji.jpg"
import muzede_drama from '../../assets/img/muzede_yaratici_drama.jpg'
import dogaclama_atolyeleri from '../../assets/img/dogaclama_atolyeleri.jpg'
import yaratici_drama_edebiyat from '../../assets/img/yaratici_drama_ile_edebiyat.jpg'
import oryanting from '../../assets/img/oryantiring.jpg'
import ogretmenlere_yonelik from '../../assets/img/ogretmenlere_yonelik_yaratici_drama_atolyeleri.jpg'
import orf from '../../assets/img/orff_atolyeleri.jpg'
import lider from '../../assets/img/lider_egitmenleri_adaylari.png'
import yaratici_drama_genclik from '../../assets/img/yaratici_drama_genclik_atolyesi.jpg'
import playback from '../../assets/img/play_back.jpg'
import forum_tiyatro from '../../assets/img/forum_tiyatro.jpg'



import "./yaratici-drama.scss"
import Spacer from '../../components/spacer/spacer';
const YaraticiDrama = () => {



  return (
    <>
      <PageHeader title="Yaratıcı Drama Atölyeleri" />
      <Container className="mt-5">
        <Accordion defaultActiveKey="0" eventKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Çocuk Atölyeleri
            </Accordion.Header>
            <Accordion.Body >
              <Card style={{ border: "none", paddingTop: "20px" }}>
                <Row className="g-0">
                  <Col xs={12} md={5} lg={3}>
                    <Spacer height={20}/>
                    <div>
                      <Card.Img
                        src={cocuk_atolye_img}
                        className="img-fluid"
                        alt=""
                        style={{ borderRadius: "0" }}

                      />
                    </div>

                  </Col>
                  <Col xs={12} md={7} lg={9}>
                    <Card.Body className="pt-3 text-center">
                      <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                        <span>
                          Çocuklar, her yaşta benzersiz gelişim özelliklerine sahiptir ve bu nedenle onlara özel bir yaklaşım gerektirirler. Biz, çocukların yaş gruplarına göre farklılık gösteren gelişim ihtiyaçlarına uygun özel tasarlanmış atölyeler sunarız. Eğitmenlerimiz, çocukların bireysel gereksinimlerini anlama ve grup dinamiklerini yönetme becerilerine sahiptir. Çocuklarımızın hayatları boyunca sürecek sağlam temeller atmalarına yardımcı olmak ve onların merakını, yaratıcılığını ve özgüvenlerini geliştirmek, en büyük önceliğimizdir. <br />
                          Çocuk atölyelerimiz, eğlenceli, etkileşimli ve öğrenmeyi teşvik eden bir ortamda çocuklarımızın büyümesine destek olur. <br />
                          Eğitimlerimiz; 4 yaş, 5-6 yaş, 7-9 yaş ve 10-12 yaş grupları için ayrı ayrı tasarlanmakta ve yürütülmektedir.
                        </span>

                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Accordion.Body>

          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Yaşam - Gelişim Atölyeleri
            </Accordion.Header>
            <Accordion.Body >
              <Card style={{ border: "none", paddingTop: "20px" }}>
                <Row className="g-0">
                  <Col xs={12} md={12} lg={9}>
                    <Card.Body className="pt-3 text-center">
                      <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                        <span>
                          Aşağıdaki eğitim başlıklarımız çocuk grupları ve yetişkin gruplarına ayrı ayrı planlanmıştır.
                        </span>
                        <Spacer height={20} />
                        <Tabs defaultActiveKey="tab1" id="my-tabs">
                          <Tab eventKey="tab1" title="Kültür /Felsefe/Mitoloji Atölyeleri">
                            <Card style={{ border: "none", paddingTop: "20px" }}>
                              <Row className="g-0">
                                <Col xs={12} md={12} lg={4}>
                                  <Spacer height={20} />
                                  <div>
                                    <Card.Img
                                      src={kultur_atolye}
                                      className="img-fluid"
                                      alt=""
                                      style={{ borderRadius: "0" }}

                                    />
                                  </div>

                                </Col>
                                <Col xs={12} md={12} lg={8}>
                                  <Card.Body className=" text-center">
                                    <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                                      <span>
                                        Çocuklardan yetişkinlere kadar her yaş grubu için tasarladığımız bu atölyeler,  katılımcıların genel kültürlerini zenginleştirirken, güncel olaylara ve toplumsal konulara duyarlılıklarını artırmalarına yardımcı olur. Yaratıcı drama yöntemleri, katılımcıların hikayelerini canlandırmalarına ve farklı bakış açıları kazanmalarına olanak tanır.
                                        <br />
                                        <br />
                                        Genel kültürü geliştirmek ve güncel konuları ele almak isteyen herkes için bu atölyeler, hem bilgi hem de yaratıcılık dolu bir deneyim sunar.
                                        <br />
                                        <br />
                                        Felsefeyle uğraşmak, çocuklara/yetişkinlere sorular sormayı, düşüncelerini ifade etmeyi ve başkalarının düşüncelerini dinlemeyi öğretir. Bu sayede katılımcıların iletişim becerileri gelişirken, empati kurma ve başkalarının farklı düşüncelerini anlama yetenekleri de artar.
                                        <br />
                                        <br />
                                        Mitoloji hayatımızın her yerinde, bir filmde, günlük ritüellerimizde, bilmesek de kullandığımız sözcüklerde, bilimde, sanatta her yerde. Mitoloji öğrendikçe hayatın şifrelerini çözeriz. Ama mitoloji eğitiminde esas olan yaş gruplarına göre çocuklara doğru yönlendirmeleri yapmak, merak dolu sorularına doğru cevapları verebilmektir!)
                                        <br />
                                        <br />
                                        Bu gruptaki eğitimleri, Meta Drama’da farklı kılan seçilen konunun yaratıcı drama yöntemi ile zenginleştirilerek, oyunun, canlandırmanın, yaratıcılığın sınırlarını zorlayan bir eğitime dönüşmesidir.



                                      </span>

                                    </Card.Text>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>

                          </Tab>
                          <Tab eventKey="tab2" title="Müzede Yaratıcı Drama">
                            <Card style={{ border: "none", paddingTop: "20px" }}>
                              <Row className="g-0">
                                <Col xs={12} md={12} lg={4}>
                                  <Spacer height={20} />
                                  <div>
                                    <Card.Img
                                      src={muzede_drama}
                                      className="img-fluid"
                                      alt=""
                                      style={{ borderRadius: "0" }}

                                    />
                                  </div>

                                </Col>
                                <Col xs={12} md={12} lg={8}>
                                  <Card.Body className=" text-center">
                                    <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                                      <span>
                                        Müze Gezme Zevkini Edinme, Bugünün Gözü ile Antik Çağları Yaratıcı Drama Yöntemiyle Deneyimleme ve Öğrenme, geçmişin izlerini günümüzde canlandıran büyülü bir maceranın kapılarını aralar. Biz, müzelerin sunduğu tarihi hazineleri keşfetmek için yaratıcı drama yöntemini kullanarak katılımcılarımıza benzersiz bir deneyim sunuyoruz. Bu atölyelerde, antik çağların yaşamını ve olaylarını yeniden canlandırmak, tarihi figürleri anlamak ve tarihsel bağlamları görmek için katılımcılar, kendi yaratıcılıklarını kullanırlar. Eğitmenlerimiz, müze gezilerini sergi eşyalarını izlemekten çıkarıp, tarihi bir yolculuğa çıkmak ve geçmişin büyüsünü yeniden yakalamak için bir fırsat haline getirmektedir.
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Tab>
                          <Tab eventKey="tab3" title="Orff Atölyeleri">
                            <Card style={{ border: "none", paddingTop: "20px" }}>
                              <Row className="g-0">
                                <Col xs={12} md={12} lg={4}>
                                  <Spacer height={20} />
                                  <div>
                                    <Card.Img
                                      src={orf}
                                      className="img-fluid"
                                      alt=""
                                      style={{ borderRadius: "0" }}

                                    />
                                  </div>

                                </Col>
                                <Col xs={12} md={12} lg={8}>
                                  <Card.Body className=" text-center">
                                    <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                                      <span>
                                        Müze Gezme Zevkini Edinme, Bugünün Gözü ile Antik Çağları Yaratıcı Drama Yöntemiyle Deneyimleme ve Öğrenme, geçmişin izlerini günümüzde canlandıran büyülü bir maceranın kapılarını aralar. Biz, müzelerin sunduğu tarihi hazineleri keşfetmek için yaratıcı drama yöntemini kullanarak katılımcılarımıza benzersiz bir deneyim sunuyoruz. Bu atölyelerde, antik çağların yaşamını ve olaylarını yeniden canlandırmak, tarihi figürleri anlamak ve tarihsel bağlamları görmek için katılımcılar, kendi yaratıcılıklarını kullanırlar. Eğitmenlerimiz, müze gezilerini sergi eşyalarını izlemekten çıkarıp, tarihi bir yolculuğa çıkmak ve geçmişin büyüsünü yeniden yakalamak için bir fırsat haline getirmektedir.
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Tab>
                          <Tab eventKey="tab4" title="Doğaçlama Atölyeleri">
                          <Card style={{ border: "none", paddingTop: "20px" }}>
                              <Row className="g-0">
                                <Col xs={12} md={12} lg={4}>
                                  <Spacer height={20} />
                                  <div>
                                    <Card.Img
                                      src={dogaclama_atolyeleri}
                                      className="img-fluid"
                                      alt=""
                                      style={{ borderRadius: "0" }}

                                    />
                                  </div>

                                </Col>
                                <Col xs={12} md={12} lg={8}>
                                  <Card.Body className=" text-center">
                                    <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                                      <span>
                                      Doğaçlama, anlık yaratıcılığın ve hazır bulunuşluğun en saf ve özgürce ifade edildiği bir sanat formudur. Doğaçlama, katılımcıları kendi içsel potansiyellerini keşfetmeye ve ifade etmeye teşvik eder. Anlık kararlar, yaratıcılık ve iletişim becerilerini geliştirirken, aynı zamanda kişisel özgüveni artırır. Atölyelerimiz, katılımcıların kendilerini daha derinden anlamalarına ve günlük yaşamlarında daha yaratıcı düşünmelerine yardımcı olur. Biz, doğaçlamanın bu gücünü keşfetmenizi ve kişisel gelişiminizde nasıl bir etki yaratabileceğini vurgulayan etkileyici atölyeler sunuyoruz.
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Tab>
                          <Tab eventKey="tab5" title="Yaratıcı Drama İle Edebiyat / Kitap Tanıtımı">
                          <Card style={{ border: "none", paddingTop: "20px" }}>
                              <Row className="g-0">
                                <Col xs={12} md={12} lg={4}>
                                  <Spacer height={20} />
                                  <div>
                                    <Card.Img
                                      src={yaratici_drama_edebiyat}
                                      className="img-fluid"
                                      alt=""
                                      style={{ borderRadius: "0" }}

                                    />
                                  </div>

                                </Col>
                                <Col xs={12} md={12} lg={8}>
                                  <Card.Body className=" text-center">
                                    <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                                      <span>
                                      Edebiyatı yaşamak; kitapların sayfalarından çıkarak karakterlerin ayakkabılarını giymek, hikayelerin içinde yolculuk yapmak, edebiyatı yaşamak demektir. Kitapların dünyasını yeniden yaratmak ve hikayeleri canlandırmak için katılımcılarımıza özgürlük ve yaratıcılık sunuyoruz. Her karakterin sesini duymak, her sahneyi yeniden inşa etmek ve her hikayeyi derinlemesine yaşamak, edebiyatı tam anlamıyla yaşamanın bir yoludur. Atölyelerimiz, katılımcıları edebiyatın büyülü dünyasında özgürce dolaşmaya teşvik eder ve kelimelerin gücünü keşfetmelerine yardımcı olur. Biz, edebiyatı sadece okumakla değil, yaşayarak deneyimlemeniz için doğaçlama yöntemini kullanarak atölyeler yapıyoruz.
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Tab>
                          <Tab eventKey="tab6" title="Oryantiring">
                          <Card style={{ border: "none", paddingTop: "20px" }}>
                              <Row className="g-0">
                                <Col xs={12} md={12} lg={4}>
                                  <Spacer height={20} />
                                  <div>
                                    <Card.Img
                                      src={oryanting}
                                      className="img-fluid"
                                      alt=""
                                      style={{ borderRadius: "0" }}

                                    />
                                  </div>

                                </Col>
                                <Col xs={12} md={12} lg={8}>
                                  <Card.Body className=" text-center">
                                    <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                                      <span>
                                      Katılımcılarımız, keşfetmeyi ve öğrenmeyi bir araya getiren eğlenceli atölyelerimizde harika deneyimler yaşarlar. Farklı mekanları bulmaca çözerek keşfederken, yaratıcı drama yöntemleri ile eğlenceli doğaçlamalar yaparlar.  Atölyelerimiz, katılımcılarımızın keşfetme arzusunu canlı tutar ve onların özgüvenlerini geliştirir. 
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Tab>
                          <Tab eventKey="tab7" title="Öğretmenlere Yönelik Yaratıcı Drama Atölyeleri">
                          <Card style={{ border: "none", paddingTop: "20px" }}>
                              <Row className="g-0">
                                <Col xs={12} md={12} lg={4}>
                                  <Spacer height={20} />
                                  <div>
                                    <Card.Img
                                      src={ogretmenlere_yonelik}
                                      className="img-fluid"
                                      alt=""
                                      style={{ borderRadius: "0" }}

                                    />
                                  </div>

                                </Col>
                                <Col xs={12} md={12} lg={8}>
                                  <Card.Body className=" text-center">
                                    <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                                      <span>
                                      Öğretmenlere Yönelik Yaratıcı Drama Atölyeleri, öğretmenlerin sınıflarını daha etkili ve yaratıcı bir öğrenme alanına dönüştürmelerini amaçlar. Eğitimimizi tamamlayan öğretmenler, kendi branşlarındaki derslerde yaratıcı drama yöntemini uygulama yeteneğine sahip olurlar. Hedefimiz, eğitim kurumlarında öğrencilerin tüm derslerde yaratıcı drama yöntemi ile etkileşimde bulunmalarını sağlamaktır. Bu sayede öğrenciler, bilgi edinme ve kullanma kapasitelerini artırırken, etkin düşünme, sorgulama ve yeni fikirler geliştirme becerilerini geliştirirler. Eğitimde yaratıcı drama yönteminin gücü, öğrencilerin öğrenme deneyimlerini zenginleştirir ve eğitim kurumlarında yenilikçi bir yaklaşımın kapılarını aralar."
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Tab>
                          <Tab eventKey="tab8" title="Lider Eğitmenleri / Lider Eğitmenliği Adayları">
                          <Card style={{ border: "none", paddingTop: "20px" }}>
                              <Row className="g-0">
                                <Col xs={12} md={12} lg={4}>
                                  <Spacer height={20} />
                                  <div>
                                    <Card.Img
                                      src={lider}
                                      className="img-fluid"
                                      alt=""
                                      style={{ borderRadius: "0" }}

                                    />
                                  </div>

                                </Col>
                                <Col xs={12} md={12} lg={8}>
                                  <Card.Body className=" text-center">
                                    <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                                      <span>
                                      <strong>Proje Danışmanlığı</strong> <br />
                                      Çağdaş Drama Derneğindeki Lider Eğitmenliği programının altıncı aşamasında olup, bitirme projesini geliştirmekte ve / veya tamamlamakta desteğe gereksinim duyan Lider adaylarına, bu kapsamda destek olmaktayız. Bu kapsamda, lider adayının konu seçimi, alan taraması, uygun ölçme değerlendirme metoduna karar vermesi / metodu uygulaması, kazanımları belirlemesi, vb konularında kolay ve başarılı ilerlemesine destek oluyoruz. <br /> <br />
                                      <strong>Yaratıcı Drama Lider Koçluğu </strong>
                                 
                                      Çağdaş Drama Derneğinin Lider Eğitmenlik Programının tüm aşamalarındaki katılımcıların, yaratıcı drama öğretmeni olma yolunda potansiyellerini / yeteneklerini ortaya çıkarmak, hedeflerine ulaşmalarına rehberlik etmek üzere Erickson Koçluk Tekniklerini kullanarak rehberlik ediyoruz
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Tab>
                        </Tabs>
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Accordion.Body>

          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Tiyatro
            </Accordion.Header>
            <Accordion.Body >
              <Card style={{ border: "none", paddingTop: "20px" }}>
                <Row className="g-0">
                  <Col xs={12} md={12} lg={9}>
                    <Card.Body className="pt-3 text-center">
                      <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                        <Spacer height={20} />
                        <Tabs defaultActiveKey="tab1" id="my-tabs">
                          <Tab eventKey="tab1" title="Yaratıcı Drama ve Gençlik Tiyatrosu">
                            <Card style={{ border: "none", paddingTop: "20px" }}>
                              <Row className="g-0">
                                <Col xs={12} md={12} lg={4}>
                                  <Spacer height={20} />
                                  <div>
                                    <Card.Img
                                      src={yaratici_drama_genclik}
                                      className="img-fluid"
                                      alt=""
                                      style={{ borderRadius: "0" }}

                                    />
                                  </div>

                                </Col>
                                <Col xs={12} md={12} lg={8}>
                                  <Card.Body className=" text-center">
                                    <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                                      <span>
                                      Yaratıcı drama ve tiyatro sanatının büyülü dünyası, gençlerimizi sadece sahneye değil, hayata hazırlamanın en eğlenceli yoludur. Atölyelerimizde, gençlerimizi özgüvenle doldururken, iletişim becerilerini geliştirmelerine ve yaratıcılıklarını sınırlarını zorlamalarına yardımcı oluyoruz. Sahne ışıkları altında kendilerini ifade ederken, gerçek dünyada da liderlik, işbirliği ve problem çözme becerileri kazanıyorlar. Onların kendi hikayelerini yazmalarına ve sahneye koymalarına yardımcı oluyoruz, çünkü inanıyoruz ki bu deneyimler sadece tiyatro sahnesinde değil, hayatın her alanında parlaklıkla parlamalarını sağlıyor.


                                      </span>

                                    </Card.Text>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>

                          </Tab>
                          <Tab eventKey="tab2" title="Play-Back ">
                            <Card style={{ border: "none", paddingTop: "20px" }}>
                              <Row className="g-0">
                                <Col xs={12} md={12} lg={4}>
                                  <Spacer height={20} />
                                  <div>
                                    <Card.Img
                                      src={playback}
                                      className="img-fluid"
                                      alt=""
                                      style={{ borderRadius: "0" }}

                                    />
                                  </div>

                                </Col>
                                <Col xs={12} md={12} lg={8}>
                                  <Card.Body className=" text-center">
                                    <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                                      <span>
                                      Play-Back Tiyatrosu çalışmalarında katılımcılarımızın gerçek hikayelerini sahneye taşıyor,  kendi deneyimlerini ve duygularını sahne üzerinde ifade etme fırsatı sunuyoruz.  Bu yolla, empati geliştirmelerine ve başkalarının bakış açısını anlamalarına yardımcı olmakla beraber hikayeleri, bir sahne üzerinde canlandığında, katılımcılarımızın duygusal bağlarının güçlenmesine ve hikayelerinin anlamının daha derin bir şekilde anlaşılmasına yol açıyoruz.
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Tab>
                          <Tab eventKey="tab3" title="Forum Tiyatro ">
                            <Card style={{ border: "none", paddingTop: "20px" }}>
                              <Row className="g-0">
                                <Col xs={12} md={12} lg={4}>
                                  <Spacer height={20} />
                                  <div>
                                    <Card.Img
                                      src={forum_tiyatro}
                                      className="img-fluid"
                                      alt=""
                                      style={{ borderRadius: "0" }}

                                    />
                                  </div>

                                </Col>
                                <Col xs={12} md={12} lg={8}>
                                  <Card.Body className=" text-center">
                                    <Card.Text style={{ fontSize: "1.1rem" }} className='text-start'>
                                      <span>
                                      Güçlü bir etkileşim ve katılım aracı olan Forum Tiyatrosu çalışmalarında katılımcıların aktif olarak sahneye çıkarak üstlendikleri roller ile interaktif bir deneyim yaşamasını sağlıyor, böylece Forum Tiyatrosu ile sorunları ve zorlukları ele almanın yaratıcı ve etkili bir yolunu sunuyoruz. Katılımcılar, gerçek yaşam senaryoları üzerinden çeşitli sorunları modelleyebilir ve alternatif çözüm yollarını deneyimleyebilirler. Bu, iletişim becerilerini güçlendirmek, problem çözme yeteneklerini artırmak ve topluluklar arasındaki anlayışı geliştirmek için güçlü bir araçtır. 
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </Col>
                              </Row>
                            </Card>
                          </Tab>
                       
                        </Tabs>
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Accordion.Body>

          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  )
}

export default YaraticiDrama;