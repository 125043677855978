const style={
    fontWeight:'500'
}

const Copyrigt = () => {
    return (
        <div>
            <ul className='text-center list-unstyled' style={style}>
            <li>Copyrights © Metadrama Yönetim Danışmanlık &copy; {new Date().getFullYear()}</li>
            <li>Tüm Hakları Saklıdır.</li>
        </ul> 
        </div>
    )
}

export default Copyrigt