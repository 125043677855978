import { Container } from "react-bootstrap";
import PageHeader from "../../components/page-header/page-header";
import Spacer from "../../components/spacer/spacer";
import "./about.scss"
const AboutUs = () => {
  return (
    <>

      <PageHeader title="Biz Kimiz" />
      <Spacer height={10} />
      <Container fluid className="p-0">

        <div className="about">
          <ul>
            <h3>META DRAMA EĞİTİM VE DANIŞMANLIK</h3><br />
            <h4>"Kalite, Yaratıcılık ve Verimliliği Birleştiriyoruz"</h4><br />
            <li>Meta Drama, eğitim öğretim ve Endüstri Mühendisliği alanlarındaki
             <br />uzmanlıklarını, 20 yılı aşkın süredir yaratıcı drama yöntemiyle
             <br />harmanlayan eğitmenleri ile kurumsal dünyaya, yetişkinlere ve
             <br /> çocuklara eğitim/danışmanlık sunar. <br /><br />
             Bu yolla ihtiyaç duyulan bilgi ve davranışlar, teori ve pratiği <br />
             birleşmesiyle ezberlemenin yerine deneyimleme ve yaratıcılık <br />
             temelinde kalıcı olarak kazanılır. </li><br />
          </ul>
        </div>


      </Container>
    </>
  );
};

export default AboutUs;
